import React, { useMemo } from 'react';
import type { Document } from '@contentful/rich-text-types';
import type { PaperProps } from '@mui/material';
import { Box, ClickAwayListener, Unstable_Grid2 as Grid, Paper, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useElementSize } from '@aph/hooks/use-element-size';
import CloseIcon from '@aph/icons/close.svg';
import { Typography } from '@aph/ui/components/typography/typography';
import { mergeRefs } from '@aph/utilities/react-merge-refs';
import { withUnmountOnRouteChange } from '@aph/utilities/with-unmount-on-route-change';
import { RichTextComponent } from '~/contentful/components/rich-text/richtext.component';
import { IconButton } from '../common/icon-button/icon-button.component';

/**
 * @note
 * Should this be moved to src/contentful?
 * It's related to a page called ListPage, should ListPage be a component on it's own?
 */
export type ListPageHeaderProps = {
  title: string;
  description: Document | undefined;
  fullWidth?: boolean;
};

const ROW_HEIGHT = 22; // line height
const MAX_LINE_AMOUNT = 4;
const DEFAULT_TRUNCATED_HEIGHT = ROW_HEIGHT * MAX_LINE_AMOUNT + 16; // line-height * amount of lines + margin top and bottom for first paragraph
const ADJUSTED_TRUNCATED_HEIGHT = DEFAULT_TRUNCATED_HEIGHT - 8; // DEFAULT_TRUNCATED_HEIGHT - bottom margin for first paragraph

const useIsOverflowing = () => {
  const [elementRef, { height }] = useElementSize({ scrollSize: true });
  const isOverflowing = useMemo(() => height > DEFAULT_TRUNCATED_HEIGHT, [height]);

  return [elementRef, isOverflowing] as const;
};

const PageHeader = ({ title }: Pick<ListPageHeaderProps, 'title'>) => (
  <Typography typography="headingExtraLarge" color="text-brand" className="mb-[1px]" asChild>
    <h1>{title}</h1>
  </Typography>
);

const TooltipPopup = React.forwardRef<React.ComponentRef<typeof Paper>, PaperProps>(
  ({ children, sx = [], ...props }, forwardedRef) => {
    return (
      <Paper
        {...props}
        ref={forwardedRef}
        sx={[
          {
            position: 'relative',
            borderRadius: 6,
            boxShadow: `0px 4px 8px rgba(48, 49, 51, 0.10), 0px 2px 4px rgba(48, 49, 51, 0.10)`,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Paper>
    );
  },
);

export const ListPageHeader = withUnmountOnRouteChange(
  ({ title, description, fullWidth = false }: ListPageHeaderProps) => {
    const [containerRef, isOverflowing] = useIsOverflowing();
    const [isOpen, setIsOpen] = React.useState(false);

    const [elementRef, { width }] = useElementSize();

    const hasDescription = (description?.content?.length ?? 0) > 0;

    const extendedDescription = hasDescription ? (
      <>
        <Box
          position="absolute"
          top={(theme) => theme.spacing(3)}
          right={(theme) => theme.spacing(3)}
        >
          <IconButton icon={CloseIcon} onClick={() => setIsOpen(false)} />
        </Box>
        <Box padding={4}>
          <PageHeader title={title} />
          <RichTextComponent field={description} />
        </Box>
      </>
    ) : null;

    return (
      <Box>
        <PageHeader title={title} />
        {hasDescription ? (
          <Grid container>
            <Grid
              xs={12}
              sm={fullWidth ? 12 : 6}
              overflow="hidden"
              maxHeight={ADJUSTED_TRUNCATED_HEIGHT}
              ref={mergeRefs([containerRef, elementRef])}
              sx={{
                WebkitLineClamp: MAX_LINE_AMOUNT,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                textOverflow: 'ellipsis',
                'h2,p:nth-child(2)': { visibility: 'hidden' },
              }}
            >
              <RichTextComponent field={description} />
            </Grid>
          </Grid>
        ) : null}
        {isOverflowing ? (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <Box>
              <Tooltip
                open={isOpen}
                title={extendedDescription}
                onClose={() => setIsOpen(false)}
                placement="bottom-start"
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    modifiers: [
                      {
                        name: 'flip',
                        enabled: false,
                      },
                    ],
                  },
                }}
                slots={{
                  tooltip: TooltipPopup,
                }}
                slotProps={{
                  tooltip: {
                    sx: {
                      maxWidth: `${width}px`,
                    },
                  },
                  arrow: {
                    sx: {
                      color: (theme) => theme.palette['color/text/inverse'],
                    },
                  },
                }}
              >
                <button
                  onClick={() => setIsOpen(true)}
                  aria-label="Läs mer"
                  className="-mb-1 mt-1 pb-1"
                  type="button"
                >
                  <Typography className="font-bold">
                    <FormattedMessage id="CATEGORY_LIST.HEADER.EXPAND" />
                  </Typography>
                </button>
              </Tooltip>
            </Box>
          </ClickAwayListener>
        ) : null}
      </Box>
    );
  },
);
