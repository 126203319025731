import type { LegacyRef, MutableRefObject, RefCallback } from 'react';

/**
 * Merges multiple refs into a single ref.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeRefs<TRefObject = any>(
  refs: Array<MutableRefObject<TRefObject> | LegacyRef<TRefObject> | undefined | null>,
): RefCallback<TRefObject> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        (ref as MutableRefObject<TRefObject | null>).current = value;
      }
    });
  };
}
