import { useRouter } from 'next/router';

/**
 * A higher-order component that unmounts the wrapped component when the route changes.
 * ref: https://nextjs.org/docs/pages/api-reference/functions/use-router#resetting-state-after-navigation

 * @param {React.FC<TComponentProps>} Component - The component to wrap.
 */
export function withUnmountOnRouteChange<TComponentProps>(Component: React.FC<TComponentProps>) {
  const WrappedComponent = (props: TComponentProps) => {
    const router = useRouter();

    return <Component key={router.asPath} {...props} />;
  };

  return WrappedComponent;
}
