import { useEffect, useLayoutEffect } from 'react';

/**
 * A custom hook that provides a consistent interface for using either `useLayoutEffect` or `useEffect`
 * based on whether the code is running in a browser environment or not.
 *
 * @returns The appropriate effect hook based on the environment.
 */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;
