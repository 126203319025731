import { useCallback, useState } from 'react';
import { useEventListener } from './use-event-listener';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';

interface Size {
  width: number;
  height: number;
}

type Options = {
  /**
   * measure the scroll size instead of the offset size
   */
  scrollSize?: boolean;
};

/**
 * Custom hook that returns a ref and the size of an element.
 * The size object contains the width and height of the element.
 *
 * It will update when the window is resized.
 */
export function useElementSize<TRef extends HTMLElement = HTMLDivElement>(
  { scrollSize }: Options = { scrollSize: false },
): [(node: TRef | null) => void, Size] {
  const [ref, setRef] = useState<TRef | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    const measurement = {
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
    };

    if (scrollSize) {
      measurement.width = ref?.scrollWidth || 0;
      measurement.height = ref?.scrollHeight || 0;
    }
    setSize(measurement);
  }, [ref?.offsetHeight, ref?.offsetWidth, ref?.scrollHeight, ref?.scrollWidth, scrollSize]);

  useEventListener('resize', handleResize);

  useIsomorphicLayoutEffect(() => {
    handleResize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}
